import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Paper } from '@material-ui/core'

const styles = {
    progress: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        flex: 1
    }
}


export class WaitCtrl extends Component {
    render() {
        const { classes, msg } = this.props;
        return <Paper style={{ paddingBottom: 10, paddingTop: 10, paddingLeft: 22, paddingRight: 22 }}>
            <Grid direction="row" alignItems="center" justify="center" container >
                <CircularProgress size={20} style={{ marginRight: 10 }} />
                {msg ? msg : 'Processando...'}
            </Grid>
        </Paper>        
    }
}

export default withStyles(styles)(WaitCtrl)
