import React, { Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
//import ProgressBar from './components/circularProgress/ProgressBar'
import { Grid } from '@material-ui/core';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { connect } from 'react-redux'
import WaitCtrl from './component/waitCtrl'
import * as ActionManager from './store/actions/manager.action'

const Home = lazy(() => import('./Pages/home'))
const OndeVoceTa = lazy(() => import('./Pages/ondevoceta'))
const TeacherLogin = lazy(() => import('./Pages/teacher/login'))
const Teacher = lazy(() => import('./Pages/teacher/index'))

const ManagerLogin = lazy(() => import('./Pages/administrador/manager.login'))
const ManagerMaster = lazy(() => import('./Pages/administrador/manager.master'))
const ManagerAcademia = lazy(() => import('./Pages/administrador/manager.academia'))

const AlunoLogin = lazy(() => import('./Pages/aluno/aluno.login'))
const AlunoHome = lazy(() => import('./Pages/aluno/aluno.home'))


const INTERVALO = 15000

const Routes = (props) => {

    const { manager, setManager } = props

    async function requireAuth(to, from, next) {
        if (to.meta.auth) {
            next()
        } else if (to.meta.manager) {
            console.log('manager::', to.meta)
            /*
            if (!manager) {
                from.history.push('/manager/login')
            } else {
                if (to.meta.master & manager.key !== 0) {

                }
            }
*/
            next();
        } else {

            next()

        }
    };

    return <Router>
        <Suspense className="text-center" fallback={<WaitCtrl />}>
            <GuardProvider guards={[requireAuth]}>
                <Switch>
                    <GuardedRoute exact path="/teacher/login" component={TeacherLogin} redirectTo='/' />
                    <GuardedRoute exact path="/teacher" component={Teacher} redirectTo='/' />
                    <GuardedRoute exact path="/onde-voce-ta/:id" component={OndeVoceTa} redirectTo='/' />

                    <GuardedRoute exact path="/manager/login" component={ManagerLogin} redirectTo='/' />
                    <GuardedRoute exact path="/manager/master" component={ManagerMaster} redirectTo='/'
                        meta={{ manager: true, master: true }} />
                    <GuardedRoute exact path="/manager/academia" component={ManagerAcademia} redirectTo='/'
                        meta={{ manager: true, master: true }} />

                    <GuardedRoute exact path="/aluno/login" component={AlunoLogin} redirectTo='/' />
                    <GuardedRoute exact path="/aluno" component={AlunoHome} redirectTo='/' />

                    <Route path="/" component={Home} />
                </Switch>
            </GuardProvider>
        </Suspense>
    </Router>
}


const mapStateToProps = state => ({
    manager: state.managerReducer.manager,
});

const mapDispatchToProps = dispatch => ({
    setManager: (manager) => dispatch(ActionManager.SET_MANAGER(manager)),

})


export default connect(mapStateToProps, mapDispatchToProps)(Routes);