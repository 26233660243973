
import { combineReducers } from 'redux'
import managerReducer from '../reduces/manager.reducer'
import drawerReducer from '../reduces/drawer.reducer'
 
const rootReducer = combineReducers({
   managerReducer,
   drawerReducer
})

export default rootReducer;