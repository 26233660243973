
import { ACTION_DRAWER_TYPES } from '../actions/drawer.action'

const initialState = {
    open: false,
    open2:false
}

const drawerReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_DRAWER_TYPES.SET_OPEN:     
       // console.log('drawer')      
            return {
                ...state,
                open: action.open
            }       

            case ACTION_DRAWER_TYPES.SET_OPEN2:     
            // console.log('drawer')      
                 return {
                     ...state,
                     open2: action.open2
                 }       
            

        default:  
            return state;
    }
}


export default drawerReducer;