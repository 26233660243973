
import { ACTION_MANAGER_TYPES } from '../actions/manager.action'

const initialState = {
    manager: undefined,
    academia: undefined,
    teacher: undefined,
    aluno: undefined,
    ctrl: 0
}

const managerReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_MANAGER_TYPES.SET_MANAGER:
            return {
                ...state,
                manager: action.manager
            }

        case ACTION_MANAGER_TYPES.SET_ACADEMIA:
            return {
                ...state,
                academia: action.academia
            }

        case ACTION_MANAGER_TYPES.SET_CONTROLER:
            return {
                ...state,
                ctrl: action.ctrl
            }

        case ACTION_MANAGER_TYPES.SET_TEACHER:
            return {
                ...state,
                teacher: action.teacher
            }

        case ACTION_MANAGER_TYPES.SET_ALUNO:
            return {
                ...state,
                aluno: action.aluno
            }

        default:
            return state;
    }
}


export default managerReducer;