export const ACTION_DRAWER_TYPES ={
     SET_OPEN:"SET_OPEN",
     SET_OPEN2:"SET_OPEN2",
 }
 
 export const SET_OPEN = (open) => ({
     type: ACTION_DRAWER_TYPES.SET_OPEN,
     open
 })

 export const SET_OPEN2 = (open2) => ({
    type: ACTION_DRAWER_TYPES.SET_OPEN2,
    open2
})