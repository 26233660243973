
import React from 'react'
import { Provider } from 'react-redux'
import { store, persistor } from './store/store'
import './App.css';
import { PersistGate } from 'redux-persist/integration/react'

import Routes from './route'

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { purple } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';





function App() {

  

  return (
    <div className="App">
    
       <ReactNotification />     
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>     
      
    </div>
  );
}

export default App;
