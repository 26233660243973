export const ACTION_MANAGER_TYPES ={
    // SET_CATEGORIA_ATIVA:"SET_CATEGORIA_ATIVA",
     SET_MANAGER:"SET_MANAGER",
     SET_ACADEMIA:"SET_ACADEMIA",
     SET_CONTROLER:"SET_CONTROLER",
     SET_TEACHER:"SET_TEACHER",
     SET_ALUNO:"SET_ALUNO"
 }


 
 
 export const SET_MANAGER = (manager) => ({
     type: ACTION_MANAGER_TYPES.SET_MANAGER,
     manager
 })

 export const SET_ACADEMIA = (academia) => ({
    type: ACTION_MANAGER_TYPES.SET_ACADEMIA,
    academia
})

export const SET_CONTROLER = (ctrl) => ({
    type: ACTION_MANAGER_TYPES.SET_CONTROLER,
    ctrl
})

export const SET_TEACHER = (teacher) => ({
    type: ACTION_MANAGER_TYPES.SET_TEACHER,
    teacher
})


export const SET_ALUNO = (aluno) => ({
    type: ACTION_MANAGER_TYPES.SET_ALUNO,
    aluno
})